var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"r1hbV_I_MDpwU01x0dId5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const { ENV_TYPE } = process.env;

if (SENTRY_DSN === '' || ENV_TYPE === '') {
  console.error('[No Sentry env]');
} else {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.3,
    environment: ENV_TYPE,
    tunnel: '/monitoring',
  });
}
